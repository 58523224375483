<template>
  <section class="filter-wrap">
    <BasicFilterWrap
      :moreFilter="showMoreFilter"
      @confrim="onSearch"
      @reset="onResetFilter"
      @more="showMoreFilter = !showMoreFilter"
    >
      <el-form ref="form" :model="tableFilters" label-width="100px">
        <el-row :gutter="20">
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="中文名称">
              <el-input
                v-model="tableFilters.questionCn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="英文名称">
              <el-input
                v-model="tableFilters.questionEn"
                placeholder="请输入"
                clearable
                @keyup.enter.native="onSearch"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :sm="24" :md="12" :lg="8">
            <el-form-item label="分类">
              <FaqTagCascader
                :cascaderProps="{
                  multiple: true,
                }"
                v-model="tableFilters.tagsId"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-collapse-transition>
          <el-row :gutter="20" v-if="showMoreFilter">
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建日期">
                <el-date-picker
                  v-model="tableFilters.createdAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新日期">
                <el-date-picker
                  v-model="tableFilters.lastModifiedAt"
                  type="daterange"
                  style="width: 100%"
                  :default-time="['00:00:00', '23:59:59']"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="timestamp"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="更新人">
                <BasicAsyncSelect
                  v-model="tableFilters.lastModifiedById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>

            <el-col :sm="24" :md="12" :lg="8">
              <el-form-item label="创建人">
                <BasicAsyncSelect
                  v-model="tableFilters.createdById"
                  :asyncObj="{
                    dataFun: GetUsersList,
                  }"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-transition>
      </el-form>
    </BasicFilterWrap>
  </section>
</template>

<script>
import BasicAsyncSelect from "@/components/BasicAsyncSelect";
import FaqTagCascader from "@/views/faqTags/components/FaqTagCascader";
import { onClearFilter } from "@/utils/table";
import BasicFilterWrap from "@/components/BasicFilterWrap";
import { GetUsersList } from "@/views/users/api";
export default {
  components: {
    BasicAsyncSelect,
    BasicFilterWrap,
    FaqTagCascader,
  },
  data() {
    return {
      showMoreFilter: false,
      tableFilters: {
        createdAt: null,
        createdById: null,
        lastModifiedAt: null,
        lastModifiedById: null,
        questionCn: "",
        questionEn: "",
        tagsId: [],
      },
    };
  },
  computed: {
    _userOptions() {
      return this.$store.getters.userOptions;
    },
  },
  methods: {
    GetUsersList,
    onSearch() {
      this.$emit("change", this.tableFilters);
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.$emit("change", this.tableFilters);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin: 10px 10px 0 10px;
  padding: 10px;
  background-color: #fff;
}
</style>
